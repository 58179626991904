<template>
  <div>
    <div
      class="app-page-spinner"
      v-if="findLoading"
      v-loading="findLoading"
    ></div>
    <div class="app-page-toolbar">
      <span>
        <el-select
          v-model="selectedYear"
          value-key="value"
          placeholder
          style="width: auto"
          @change="setSvWerte"
        >
          <el-option
            v-for="item in svWerteYears"
            :key="item.value"
            :value="item.value"
          ></el-option>
        </el-select>
      </span>

      <el-button @click="doNew" icon="el-icon-fa-plus" type="primary">
        <app-i18n code="common.new"></app-i18n>
      </el-button>

      <el-button @click="doEdit" icon="el-icon-edit" type="primary">
        <app-i18n code="common.edit"></app-i18n>
      </el-button>
    </div>

    <app-sv-werte-form
      :isEditing="isEditing"
      :record="record"
      :saveLoading="saveLoading"
      @cancel="doCancel"
      @submit="doSubmit"
      v-if="!findLoading"
    />

    <div v-if="dialogVisible">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @close="doCancel"
        width="30%"
      >
        <app-sv-werte-form
          :modal="true"
          :isEditing="isEditing"
          :record="editRecord"
          :saveLoading="saveLoading"
          :nextYear="nextYear"
          @cancel="doCancel"
          @submit="doSubmit"
        />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SvWerteForm from '@/modules/settings/components/sv-werte-form.vue';

export default {
  name: 'app-sv-werte-form-page',

  props: ['svWerte'],

  components: {
    [SvWerteForm.name]: SvWerteForm,
  },

  data() {
    return {
      selectedYear: '',
      record: null,
      editing: false,
      dialogVisible: false,
      editRecord: null,
      title: 'SV Werte',
    };
  },

  async created() {
    this.record = this.svWerteActive;
  },

  mounted() {
    this.selectedYear = this.svWerteActive.year;
  },

  computed: {
    ...mapGetters({
      findLoading: 'settings/findLoading',
      saveLoading: 'settings/saveLoading',
      svWerteActive: 'settings/svWerteActive',
    }),

    isEditing() {
      return this.editing;
    },

    /* selectedSvWerte() {
      return this.svWerte.find((f) => f.year === this.selectedYear);
    },
 */
    svWerteYears() {
      if (this.svWerte) {
        return this.svWerte
          .map((f) => {
            return {
              value: f.year,
              label: f.year,
            };
          })
          .sort(function (a, b) {
            return a - b;
          });
      }
      return [];
    },

    activeYear() {
      return this.svWerteActive.year;
    },

    nextYear() {
      const years = this.svWerte.map((f) => {
        return parseInt(f.year);
      });
      return (Math.max.apply(null, years) + 1).toString();
    },
  },

  methods: {
    ...mapActions({
      doNew: 'settings/doNewSvWerte',
      doUpdate: 'settings/doUpdateSvWerte',
      doCreate: 'settings/doCreateSvWerte',
    }),

    setSvWerte(value) {
      this.record = this.svWerte.find((f) => f.year === value);
    },

    doCancel() {
      this.dialogVisible = false;
      this.editRecord = null;
      this.editing = false;
    },

    doNew() {
      this.editRecord = null;
      this.dialogVisible = true;
    },

    doEdit() {
      this.editing = true;
      this.editRecord = this.record;
      this.dialogVisible = true;
    },

    doDestroyAllSelected() {
      console.log('uttnon clicl');
    },

    async doSubmit(payload) {
      if (this.isEditing) {
        const { year, ...values } = payload;
        return this.doUpdate({
          year,
          values,
        });
      } else {
        return this.doCreate(payload);
      }
    },
  },
};
</script>

<style></style>
