<template>
  <div>
    <el-row :gutter="30">
      <el-col>
        <el-form
          label-position="top"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="form"
          v-if="model"
          :disabled="!modal"
        >
          <el-form-item
            :label="fields.year.label"
            :prop="fields.year.name"
            :required="fields.year.required"
            v-if="modal"
          >
            <el-input v-model="model[fields.year.name]" :disabled="true" />
          </el-form-item>

          <el-tabs>
            <el-tab-pane label="HBGL">
              <div>
                <el-row :gutter="30">
                  <el-col :span="columnSpan" :offset="0">
                    <el-form-item
                      :label="fields.geringfuegigkeitsgrenze.label"
                      :prop="fields.geringfuegigkeitsgrenze.name"
                      :required="fields.geringfuegigkeitsgrenze.required"
                    >
                      <app-money-input
                        v-model="model[fields.geringfuegigkeitsgrenze.name]"
                      ></app-money-input>
                    </el-form-item>
                    <el-form-item
                      :label="fields.hbglProTag.label"
                      :prop="fields.hbglProTag.name"
                      :required="fields.hbglProTag.required"
                    >
                      <app-money-input
                        v-model="model[fields.hbglProTag.name]"
                      ></app-money-input>
                    </el-form-item>
                    <el-form-item
                      :label="fields.hbglProMonat.label"
                      :prop="fields.hbglProMonat.name"
                      :required="fields.hbglProMonat.required"
                    >
                      <app-money-input
                        v-model="model[fields.hbglProMonat.name]"
                      ></app-money-input>
                    </el-form-item>
                    <el-form-item
                      :label="fields.hbglProJahr.label"
                      :prop="fields.hbglProJahr.name"
                      :required="fields.hbglProJahr.required"
                    >
                      <app-money-input
                        v-model="model[fields.hbglProJahr.name]"
                      ></app-money-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
            <el-tab-pane label="DGA - Prozentsätze">
              <el-row :gutter="30">
                <el-col :span="columnSpan" :offset="0">
                  <el-form-item
                    :label="fields.sv.label"
                    :prop="fields.sv.name"
                    :required="fields.sv.required"
                  >
                    <app-money-input
                      v-model="model[fields.sv.name]"
                    ></app-money-input>
                  </el-form-item>
                  <el-form-item
                    :label="fields.sv_60_bis_63.label"
                    :prop="fields.sv_60_bis_63.name"
                    :required="fields.sv_60_bis_63.required"
                  >
                    <app-money-input
                      v-model="model[fields.sv_60_bis_63.name]"
                    ></app-money-input>
                  </el-form-item>
                  <el-form-item
                    :label="fields.sv_uber_63.label"
                    :prop="fields.sv_uber_63.name"
                    :required="fields.sv_uber_63.required"
                  >
                    <app-money-input
                      v-model="model[fields.sv_uber_63.name]"
                    ></app-money-input>
                  </el-form-item>
                  <el-form-item
                    :label="fields.sv_sz.label"
                    :prop="fields.sv_sz.name"
                    :required="fields.sv_sz.required"
                  >
                    <app-money-input
                      v-model="model[fields.sv_sz.name]"
                    ></app-money-input>
                  </el-form-item>
                  <el-form-item
                    :label="fields.sv_sz_60_bis_63.label"
                    :prop="fields.sv_sz_60_bis_63.name"
                    :required="fields.sv_sz_60_bis_63.required"
                  >
                    <app-money-input
                      v-model="model[fields.sv_sz_60_bis_63.name]"
                    ></app-money-input>
                  </el-form-item>
                  <el-form-item
                    :label="fields.sv_sz_uber_63.label"
                    :prop="fields.sv_sz_uber_63.name"
                    :required="fields.sv_sz_uber_63.required"
                  >
                    <app-money-input
                      v-model="model[fields.sv_sz_uber_63.name]"
                    ></app-money-input>
                  </el-form-item>
                  <el-form-item
                    :label="fields.sv_geringfuegig.label"
                    :prop="fields.sv_geringfuegig.name"
                    :required="fields.sv_geringfuegig.required"
                  >
                    <app-money-input
                      v-model="model[fields.sv_geringfuegig.name]"
                    ></app-money-input>
                  </el-form-item>
                </el-col>
                <el-col :span="columnSpan" :offset="0">
                  <el-form-item
                    :label="fields.sv_geringfuegig_uber_60.label"
                    :prop="fields.sv_geringfuegig_uber_60.name"
                    :required="fields.sv_geringfuegig_uber_60.required"
                  >
                    <app-money-input
                      v-model="model[fields.sv_geringfuegig_uber_60.name]"
                    ></app-money-input>
                  </el-form-item>
                  <el-form-item
                    :label="fields.db.label"
                    :prop="fields.db.name"
                    :required="fields.db.required"
                  >
                    <app-money-input
                      v-model="model[fields.db.name]"
                    ></app-money-input>
                  </el-form-item>
                  <el-form-item
                    :label="fields.db_uber_60.label"
                    :prop="fields.db_uber_60.name"
                    :required="fields.db_uber_60.required"
                  >
                    <app-money-input
                      v-model="model[fields.db_uber_60.name]"
                    ></app-money-input>
                  </el-form-item>
                  <el-form-item
                    :label="fields.dz.label"
                    :prop="fields.dz.name"
                    :required="fields.dz.required"
                  >
                    <app-money-input
                      v-model="model[fields.dz.name]"
                    ></app-money-input>
                  </el-form-item>
                  <el-form-item
                    :label="fields.bv.label"
                    :prop="fields.bv.name"
                    :required="fields.bv.required"
                  >
                    <app-money-input
                      v-model="model[fields.bv.name]"
                    ></app-money-input>
                  </el-form-item>
                  <el-form-item
                    :label="fields.koSt.label"
                    :prop="fields.koSt.name"
                    :required="fields.koSt.required"
                  >
                    <app-money-input
                      v-model="model[fields.koSt.name]"
                    ></app-money-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
          <el-form-item>
            <div class="form-buttons">
              <el-button
                :disabled="saveLoading"
                @click="doSubmit"
                icon="el-icon-fa-floppy-o"
                type="primary"
              >
                <app-i18n code="common.save"></app-i18n>
              </el-button>

              <el-button
                :disabled="saveLoading"
                @click="doCancel"
                icon="el-icon-fa-close"
              >
                <app-i18n code="common.cancel"></app-i18n>
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { SvWerteModel } from '@/modules/settings/sv-werte-model';

const { fields } = SvWerteModel;
const formSchema = new FormSchema([
  fields.year,
  fields.geringfuegigkeitsgrenze,
  fields.hbglProTag,
  fields.hbglProMonat,
  fields.hbglProJahr,
  fields.sv,
  fields.sv_60_bis_63,
  fields.sv_uber_63,
  fields.sv_sz,
  fields.sv_sz_60_bis_63,
  fields.sv_sz_uber_63,
  fields.sv_geringfuegig,
  fields.sv_geringfuegig_uber_60,
  fields.db,
  fields.db_uber_60,
  fields.dz,
  fields.bv,
  fields.koSt,
  fields.updatedAt,
]);

export default {
  name: 'app-sv-werte-form',

  props: [
    'isEditing',
    'record',
    'selectedYear',
    'saveLoading',
    'modal',
    'nextYear',
  ],

  data() {
    return {
      rules: formSchema.rules(),
      year: '',
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
    if (!this.record && !this.isEditing) {
      this.model.year = this.nextYear;
    }
  },

  mounted() {},

  watch: {
    // whenever question changes, this function will run
    record() {
      this.model = formSchema.initialValues(this.record || {});
      if (!this.record && !this.isEditing) {
        this.model.year = this.nextYear;
      }
    },
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    fields() {
      return fields;
    },

    columnSpan() {
      return this.modal ? 12 : 4;
    },
  },

  methods: {
    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const values = formSchema.cast(this.model);

      return this.$emit('submit', values);
    },
  },
};
</script>

<style></style>
